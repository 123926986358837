@font-face {
  font-family: "BoschSansBold";
  src: url("..\..\resource\BOSCH_SANS_4_0\TTF\BoschSans-Bold.ttf")
    format("truetype");
  font-weight: normal;
  font-style: normal;
}

.MuiBottomNavigation-root {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
}

  .footer-text {
    padding-left: min(0.5%, 10px);
    padding-right: min(0.5%, 10px);
    font-family: 'boschsans','Helvetica Neue',Helvetica,Arial,sans-serif;
    font-size: 0.5rem;
}

@media (min-width: 768px) {
  .footer-text {
    padding-left: min(0.5%, 10px);
    padding-right: min(0.5%, 10px);
    font-family: 'boschsans','Helvetica Neue',Helvetica,Arial,sans-serif;
    font-size: 0.6rem;
}
}

@media (min-width: 992px) {
  .footer-text {
    padding-left: min(0.5%, 10px);
    padding-right: min(0.5%, 10px);
    font-family: 'boschsans','Helvetica Neue',Helvetica,Arial,sans-serif;
    font-size: 0.8rem;
}
}

@media (min-width: 1280px) {

  .footer-text {
    margin-right: 1%;
    font-family: 'boschsans','Helvetica Neue',Helvetica,Arial,sans-serif;
    font-size: 0.8rem;
}
}


