@font-face {
  font-family: "BoschSansBold";
  src: url("..\..\resource\BOSCH_SANS_4_0\TTF\BoschSans-Bold.ttf")
    format("truetype");
  font-weight: normal;
  font-style: normal;
}

.Text_serviceIdentifier {
  font-family: "BoschSansBold";
  font-size: 1rem;
  white-space: nowrap;
}

.supergraphic-band {
  width: "100%";
  height: "20px";
}

.responsiveimage {
  height: auto;
  width: 324px;
  display: "flex";
}

.blocker {
  width: 284px;
  display: "block";
}

.Bosch-logo {
  height: auto;
  width: 80%;
}

@media (min-width: 768px) {
  .Text_serviceIdentifier {
    font-family: "BoschSansBold";
    font-size: 1.5rem;
  }

  .responsiveimage {
    height: auto;
    width: 418px;
    display: "flex";
  }
  .blocker {
    width: 365px;
    display: "block";
  }
}

@media (min-width: 992px) {
  .Text_serviceIdentifier {
    font-family: "BoschSansBold";
    font-size: 2rem;
  }

  .responsiveimage {
    height: auto;
    width: 600px;
    display: "flex";
  }
  .blocker {
    width: 525px;
    display: "block";
  }
}

@media (min-width: 1280px) {
  .Text_serviceIdentifier {
    font-family: "BoschSansBold";
    font-size: 2.5rem;
  }

  .responsiveimage {
    height: auto;
    width: 800px;
    display: "flex";
  }
  .blocker {
    width: 700px;
    display: "block";
  }
}


